<template>
  <div>
    <h1>Datalytics</h1>
    <UploadButton @file-selected="handleFile" />

    <TableView :tableData="fileData" />
  </div>
</template>

<script>
import UploadButton from '@/components/Datalytics/UploadButton.vue';
import TableView from '@/components/Datalytics/TableView.vue';

import * as XLSX from "xlsx";
import Papa from "papaparse";

export default {
  name: 'DatalyticsApp',
  components: {
    UploadButton,
    TableView
  },
  data() {
    return {
      fileData: []
    };
  },
  methods: {
    handleFile(file) {
      const fileType = file.name.split('.').pop();
      const reader = new FileReader();

      if (fileType === 'csv') {
        reader.onload = (event) => {
          const csvData = event.target.result;

          Papa.parse(csvData, {
            complete: (result) => {
              this.fileData = result.data;
            },
            header: false
          });
        };

        reader.readAsText(file);
      } else if (fileType === 'xlsx') {
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          this.fileData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        };

        reader.readAsArrayBuffer(file);
      } else {
        alert("Unsupported file format. Please, upload a .csv or .xlsx file.")
      }
    }
  }
};
</script>

<style scoped></style>